import React from "react";

import { VStack, Text, Center, Divider, Flex } from "@chakra-ui/react";

import { ReactComponent as Logo } from "../../finstack.svg";

const CashLoan = () => {
  return (
    <main>
      <VStack
        padding="0 20px"
        m="75px auto 0 auto"
        textAlign="center"
        maxW="900px"
      >
        <Flex alignItems="center">
          <Logo style={{ width: "65px", fill: "red" }} />
          <Text fontSize="5xl" marginLeft="15px">
            Zmiana oprocentowania kredytu
          </Text>
        </Flex>
        <Text fontSize="2xl" marginTop="15px">
          Sprawdź jak zmiana oprocentowania kredytu wpłynie na wysokość Twojej
          raty.
        </Text>
      </VStack>
      <Center>
        <Divider maxW="200px" orientation="horizontal" marginTop="75px" />
      </Center>
    </main>
  );
};

export default CashLoan;
