import React, { useState, useEffect, useContext } from "react";
import useAxios from "axios-hooks";
import _ from "lodash";

import LoanForm from "./LoanForm";
import LoanInstallments from "./LoanInstallments";
import LoanSummary from "./LoanSummary";

import {
  VStack,
  Text,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import SettingsContext from "../../context/settingsContext";

import { ReactComponent as Logo } from "../../finstack.svg";

const deafultFormConfig = {
  cashLoan: {
    defaultValues: {
      principal: 21000,
      interestRate: 8,
      period: 24,
      installmentType: "equal",
    },
    principal: {
      min: 100,
      max: 40000,
      step: 100,
    },
    interestRate: {
      min: 0,
      max: 25,
      step: 0.1,
    },
    period: {
      min: 1,
      max: 180,
      step: 1,
    },
    installmentType: "equal",
  },
  mortgageLoan: {
    defaultValues: {
      principal: 340000,
      interestRate: 5,
      period: 360,
      installmentType: "equal",
    },
    principal: {
      min: 50000,
      max: 700000,
      step: 1000,
    },
    interestRate: {
      min: 0,
      max: 15,
      step: 0.1,
    },
    period: {
      min: 1,
      max: 420,
      step: 1,
    },
    installmentType: "equal",
  },
};

const CashLoan = () => {
  const { settings } = useContext(SettingsContext);

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [formConfig] = useState(deafultFormConfig.mortgageLoan);
  const [formData, changeFormData] = useState(formConfig.defaultValues);
  const [lastSuccededFormData, changeLastSuccededFormData] = useState(formData);

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: "/cash-loan/calculator",
      method: "POST",
    },
    { manual: true }
  );

  const calculateLoanInstallment = (data) => {
    execute(
      { data: { ...data, interestRate: data.interestRate / 100 } },
      { useCache: true }
    ).then(() => {
      changeLastSuccededFormData(data);
    });
  };

  // const handleErrorSnackbarClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setErrorSnackbarOpen(false);
  // };

  // const onLoanTypeChange = (event) => {
  //   const newFormConfig = event.target.checked
  //     ? deafultFormConfig.mortgageLoan
  //     : deafultFormConfig.cashLoan;

  //   if (_.isEqual(formData, formConfig.defaultValues)) {
  //     changeFormData(newFormConfig.defaultValues);
  //   }

  //   changeFormConfig(newFormConfig);
  // };

  const resetForm = () => {
    changeFormData(formConfig.defaultValues);
  };

  useEffect(() => {
    if (!!error && !errorSnackbarOpen) {
      setErrorSnackbarOpen(true);
    }

    if (!error && errorSnackbarOpen) {
      setErrorSnackbarOpen(false);
    }
  }, [error]);

  const displayError = !!error;
  const displayDiffWarning = !_.isEqual(lastSuccededFormData, formData);

  const ONE = 1;

  return (
    <main>
      {/* <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={15000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleErrorSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Nie udało się pobrać danych. Spróbuj ponownie.
        </Alert>
      </Snackbar> */}
      <VStack
        padding="0 20px"
        m="75px auto 0 auto"
        textAlign="center"
        maxW="900px"
      >
        <Flex alignItems="center">
          <Logo style={{ width: "65px", fill: "red" }} />
          <Text fontSize="6xl" marginLeft="15px">
            Kredyt gotówkowy
          </Text>
        </Flex>
        <Text fontSize="2xl" marginTop={8}>
          Oblicz ratę kredytu gotówkowego. Sprawdź strukturę raty oraz zobacz
          jaka będzie całkowita wysokość odsetek
        </Text>
      </VStack>
      <Center>
        <Divider maxW="200px" orientation="horizontal" marginTop="75px" />
      </Center>
      {/* <Box sx={{ maxWidth: 1100, margin: "0 auto 20px auto" }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            justifyContent: "end",
            margin: "80px 50px 10px 0",
          }}
        >
          <Tooltip title="Przesuń przełącznik, aby zmienić domyślne ustawienia formularza">
            <IconButton
              sx={{
                margin: "0",
              }}
              color="info"
            >
              <InfoRoundedIcon />
            </IconButton>
          </Tooltip>
          <Typography>Kredyt:</Typography>
          <Typography>kosumpcyjny</Typography>
          <MaterialUISwitch
            sx={{ m: 1 }}
            defaultChecked
            onChange={onLoanTypeChange}
          />
          <Typography>hipoteczny</Typography>
        </Stack>
      </Box> */}
      <Center marginTop="75px">
        <LoanForm
          loading={loading}
          onSubmit={calculateLoanInstallment}
          formConfig={formConfig}
          formData={formData}
          changeFormData={changeFormData}
          resetForm={resetForm}
          showHints={settings.hints}
        />
      </Center>
      {!!data ? (
        <>
          <Center marginTop="25px">
            <LoanInstallments
              error={displayError}
              warning={displayDiffWarning}
              installments={!!data ? data.installments : []}
              roundError={!!data ? data.roundError : 0}
              showHints={settings.hints}
            />
          </Center>
          <Center marginTop="25px">
            <Grid
              maxW="1000px"
              // minH="500px"
              w="100%"
              justifyContent="center"
              boxShadow="lg"
              p="30px 25px"
              m="0 50px"
              borderRadius="8px"
              border="1px solid #e7e7e9"
              bg="#EE9B00"
            >
              <GridItem colSpan={2} display="flex" alignItems="baseline">
                <Text color="white">{`Za każde pożyczone`}</Text>
                <Text as="b" fontSize="2xl" color="white" m="0 5px">
                  {ONE.toLocaleString("pl-PL", {
                    style: "currency",
                    currency: "PLN",
                  })}
                </Text>
                <Text color="white">{`oddasz około`}</Text>
                <Text as="b" fontSize="2xl" color="white" ml="5px">
                  {data.borrowRate.toLocaleString("pl-PL", {
                    style: "currency",
                    currency: "PLN",
                  })}
                </Text>
              </GridItem>
            </Grid>
          </Center>
          <Center marginTop="25px">
            <LoanSummary
              error={displayError}
              warning={displayDiffWarning}
              interestTotal={!!data ? data.interestTotal : null}
              principalTotal={!!data ? data.principalTotal : null}
              totalCost={!!data ? data.totalCost : null}
            />
          </Center>
        </>
      ) : null}
    </main>
  );
};

export default CashLoan;
