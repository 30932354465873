const homeCards = [
  {
    key: "loan_calculator",
    name: "Kredyt",
    description:
      "Oblicz ratę kredytu gotówkowego lub hipotecznego. Sprawdź strukturę raty oraz zobacz jaka będzie całkowita wysokość odsetek.",
    tags: [{ name: "Kalkulator", colorTheme: "yellow" }],
    path: "/calculator/loan",
  },
  {
    key: "loan_interest_rate_change_calculator",
    name: "Zmiana oprocentowania kredytu",
    description:
      "Sprawdź jak zmiana oprocentowania kredytu wpłynie na wysokość Twojej raty.",
    tags: [{ name: "Kalkulator", colorTheme: "yellow" }],
    path: "/calculator/interest-rate-change",
  },
];

const getFilteredCards = (searchPhrase) => {
  return homeCards.filter((card) => {
    if (
      !searchPhrase ||
      card.name.includes(searchPhrase) ||
      card.description.includes(searchPhrase)
    )
      return true;

    return false;
  });
};

export default homeCards;

export { getFilteredCards };
